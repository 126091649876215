.tooltip-section {
  position: relative;
  z-index: 999;
  background-color: #000;
}
.single-line-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text-color {
  color: #0049b7;
}

.card-padding {
  padding: 33px 8px 18px 12px;
}

.txt-decoration {
  text-decoration: none;
}

.third-svg {
  margin-bottom: 4px;
  height: 14px;
  margin-right: 5px;
}


.font-text {
  font-family: "GothamBook";
}

.text-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fourth-svg {
  text-decoration: none;
  margin-bottom: 6px;
  height: 12px;
  /* margin-right: 5px; */
  margin-left: 10px;
}

.flex.card-container {
  display: flex;
}

.card {
  border-radius: 12px !important;
}

.service-card-height {
  max-height: 250px;
}

.head-title {
  font-size: 16px;
  color: var(--selectedhead);
  line-height: 19.2px;
  display: block;
  letter-spacing: 0.8px;
}

.head-not-selected {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.75px;
  font-family: "GothamMedium";
  font-weight: 500;
  display: block;
  color: #696969;
}

.card-subtitle {
  color: #000339;
  font-family: "GothamBold";
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 700;
}

.location {
  color: rgba(0, 48, 96, 0.70);
  font-size: 14px;
  line-height: 19.07px;
}

.animal-center {
  color: #003060b2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* margin-bottom: 5px; */
  line-height: 19.07px;
}

.email-phone {
  color: #0049b7;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.logo-circle {
  color: #1F2124;
  text-align: center;
  font-family: Gotham;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid  #FAFAFA;
  background-color: white;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  position: absolute;
  top: -39px;
  left: calc(50% - 38px);
 
}

h6.card-subtitle.mb-2.text-center {
  margin-top: 10px;
}

.margin-left-6px {
  margin-left: 6px;
}
._100-height{
  height: 100vh;
}
.service-card-header {
  padding-top: 1.5%;
  vertical-align: middle;
  min-height: 2.188rem;
  font-size: 13px;
  line-height: 1.125rem;
  letter-spacing: 0.125rem;
  color: #696969;
  padding-right: 3rem;
}

.checked-mark {
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 27px;
  height: 26px;
  border-radius: 50%;
  background-color: #e3e3e3;
  /* right: 16px; */
  left: 10px;
  top: 13px;
}

.tick-mark {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 27px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  /* background-color: #09a23e; */
  /* right: 16px; */
  left: 10px;
  top: 13px;
}

.section-gap {
  display: flex;
  justify-content: space-around;
}

.service-card-subheader {
  /* font-weight: 600; */
  text-transform: capitalize;
  min-height: 1.5rem;
  font-size: 1.438rem;
  line-height: 1.688rem;
  letter-spacing: 0.031rem;
  color: #000339;
}

.service-card-para {
  font-weight: 500;
  min-height: 2.625rem;
  font-size: 13.7596px;
  line-height: 160%;
  color: #4d6e90;
  opacity: 0.7;
  overflow-y: auto;
}

.message-list-color {
  font-size: 13px;
  font-weight: 700;
  line-height: 160%;
  color: #000339;
}

.service-card-footer {
  color: #000339;
}

.show-more {
  display: flex;
}

.first-section {
  background: #F5F7F9;
}

.second-section {
  background: #F5FAFE;
}

.paragraph-text {
  color: #4141a5;
  line-height: 32px;
  font-size: 18px;
}

.speciality {
  font-size: 14px;
  color: #4D6E90;
}

.step-count {
  margin-top: 16px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cards-width {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.cards-position {
  margin-bottom: 23px;
  width: 100%;
}

.padding-25px {
  padding: 25.799px;
}
@media screen and (max-width: 764px) {
  .breadcrumb-header {
    display: none;
  }

  .step-count,
  .date-calendar,
  .contact-info-confirm
  /* .contact-info-summary */
  {
    display: none;
  }

  .heading-info {
    font-size: 20px;
  }

  #preferred-facility {
    /* display: none; */
    /* height: calc(100% - 65px); */
    height: 100%;
    padding-bottom: 70px;
    overflow-y: auto;
    /* position: relative; */
  }

  #preferred-service,
  .second-section,
  .date-calendar,
  #contact-info,
  .section-two,
  #preferred-pet {
    display: none;
    /* height: calc(100% - 65px); */
    height: 100%;
    padding-bottom: 70px;
    overflow-y: auto;
    position: relative;
  }

  #contact-info {
    display: block;
  }
  .preferred-service p.flex {
    font-weight: 600;
  }

  .facility-card {
    flex-direction: column;
    align-items: center;
  }

  .proceed-next {
    display: none;
  }

  .show-next-page {
    /* position: absolute; */
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 1px -3px 13px rgba(2, 5, 58, 0.2);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
  }

  .show-next-page .next-button {
    background-color: #09a23e;
    font-weight: 400;
    font-size: 23.0481px;
    /* line-height: 27px; */
    line-height: 35px;
    height: 41px;
    width: 124px;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    margin-right: 20px;
  }

  .preferred-service .heading-info {
    padding-top: 20px;
  }

  .switch-next-page {
    display: none;
  }

  .next-button.switch-to-facility {
    margin-left: auto;
    display: block !important;
  }

  .previous-button {
    margin-left: 20px !important;
    background-color: #02043a !important;
    
  }

  .preferred-doctor-button {
    display: none !important;
  }

  .date-calendar {
    background-color: #f4f7f9;
  }

  .mobile-verify {
    width: 100%;
  }

  .personal-form {
    margin-top: 0;
    margin-left: 0;
  }

  .pet-form {
    margin-top: 0;
    margin-left: 0;
  }

  .pet-info-section {
    margin-top: 20px !important;
  }

  .pet-info-submit {
    display: none;
  }

  .heading-sub-info {
    font-size: 15px;
  }

  #show-calendar,
  .date-calendar {
    display: none !important;
  }

  .preferred-date {
    margin-top: 20px;
  }

  .select-time-slot {
    width: 43%;
    height: 45px;
    background: #eff6fe;
    border-radius: 10px;
    border: none;
    color: #3352a3;
    font-weight: 700;
    margin: 10px;
  }

  .only-mobile {
    display: block !important;
  }

  .only-desktop {
    display: none;
  }

  .section-thanks {
    padding: 1rem 0 !important;
  }

  .summary-div,
  .date-and-time {
    margin-top: 0;
  }

  .thank-you-card {
    padding: 0.5rem !important;
  }
  #preferred-pet {
    display: none;
    overflow-y: scroll;
  }
}

.thank-you-card {
  padding: 1.5rem;
}

.facility-card ul {
  min-height: 214px;
}

.next-button.switch-to-facility {
  display: none;
}

.preffered-service-card ul {
  min-height: 340px;
}

.select-doctor-card ul {
  min-height: 340px;
}

.next-previous-button {
  display: flex;
  justify-content: space-between;
}

.next-previous-button a button {
  height: 52px;
  border-radius: 7px;
  min-width: 160px;
  border: none;
  color: #fff;
}

.active-class {
  background-color: #09a23e;
  border-radius: 10px;
  color: #FEFEFE;
}

.active-class .card-subtitle,
.active-class .location,
.active-class .animal-center,
.active-class .email-phone,
.active-class .time-duration,
.active-class .open-color,
.active-class .card-text-gray,
.active-class .down-tip-color,
.active-class a {
  color: #FEFEFE;
}

.time-duration {
  color: black;
}

.active-class a:hover {
  color: #fff;
}

.clock-icon {
  color: #4D6E90;
}

.active-service-card {
  background-color: #09a23e;
  color: #fff;
  border-radius: 10px;
}

.active-service-card .service-card-header,
.active-service-card .service-card-subheader,
.active-service-card .service-card-para,
.active-service-card .service-card-footer,
.active-service-card .clock-icon,
.active-service-card .speciality,
.active-service-card .message-list-color {
  color: #fff;
}

.grid-section {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  margin-top: 30px;
}

.cards-margin-desktop {
  margin-bottom: 1.5rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-class {
  vertical-align: bottom;
  display: inline-block;
}

.icon,
.text {
  vertical-align: middle;
  display: inline-block;
}

.service-card-min-height {
  min-height: 260px !important;
}

@media (max-width: 580px) {
  .logo-circle{
    width: 60px;
    height: 60px;
    top: -37px;
    border: 1px solid  #FAFAFA;
    background-color: white;
  }

  .cards-margin {
    margin-bottom: 3rem !important;
  }

  .flex-center {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .service-card-min-height {
    min-height: initial !important;
  }

  .head-margin {
    margin-top: 28px;
  }

  .paragraph-text {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
  }

  .service-card-height {
    max-height: 210px;
  }

  .show-next-page .next-button {
    font-size: 18px;
  }
}

@media (max-width: 780px) {
  .cards-margin {
    margin-bottom: 2.8rem;
  }
}

@media (max-width: 768px) {
  .top-container {
    max-width: 720px;
  }
}